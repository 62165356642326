import React from 'react';
import './brand.css';

function Brand() {
    return (
    <div className='title-brand'>
        <t className='gradient__text brand-title'>Descubre el genio que llevas dentro</t>
    </div>
)
}

export default Brand
import React from 'react';
import {Brand, CTA, Navbar } from './components/index.js';
import { Blog, Features, Footer, Header, Possibility, WhatGPT3, Formaciondebase } from './containers/index.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Dinamica from './containers/cursos/Dinamica.jsx';
import Autoestima from './containers/cursos/Autoestima.jsx';
import ScrollToTop from './components/scroll/Scrolltotop.jsx';
import Metodosilva from './containers/metodosilva/Metodosilva.jsx';
import Metodologia from './containers/metodosilva/Metodologia.jsx';
import Instructora from './containers/instructora/Instructora.jsx';
import { testimonials } from './containers/testimonials/Testimonials.jsx';

import { Analytics } from '@vercel/analytics/react';
import Testimonials from './containers/testimonials/Testimonials.jsx';

const App = () => {
  function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  var random1, random2, random3;
  random1 = getRandomIntInclusive(0, testimonials.length);
  random2 = getRandomIntInclusive(0, testimonials.length);
  random2 = getRandomIntInclusive(0, testimonials.length);

  if (random1 == random2) {
    random2 = getRandomIntInclusive(0, testimonials.length);
    if (random1 == random2) {
      random2 = getRandomIntInclusive(0, testimonials.length);
    }
    if (random2 == random3) {
      random3 = getRandomIntInclusive(0, testimonials.length);
    }
    if (random3 == random1) {
      random3 = getRandomIntInclusive(0, testimonials.length);
    }
  }


    return (
      <Router>
        <Routes>
          <Route path='/' element={
              <div className='App'>
                <Analytics />
                <div className='gradient__bg'>
                  <Navbar />
                  <Header />
                </div>
                <Brand />
                <WhatGPT3 />
                <Features />
                <CTA />
                <Blog />
                <Possibility />
              {/*
              <div className="testimonial-container">
                <h1 className='gradient__text'>Testimonios de graduados</h1>
                <div className="testimonials-grid">

                  {testimonials.map((testimonial) => (
                    testimonial.id == 1 ? <div key={testimonial.id} className="testimonial-item">
                      <h3>{testimonial.name}</h3>
                      <p>{testimonial.message}</p>
                    </div>
                      : testimonial.id == 4 ? <div key={testimonial.id} className="testimonial-item">
                        <h3>{testimonial.name}</h3>
                        <p>{testimonial.message}</p>
                      </div>
                        : testimonial.id == 3 ? <div key={testimonial.id} className="testimonial-item">
                          <h3>{testimonial.name}</h3>
                          <p>{testimonial.message}</p>
                        </div>
                          : null
                  ))}
                </div>

                <a href="/testimonials" className='testimoniobutton'>Ver más testimonios</a>

              </div> */}

                <Footer />
              </div>
            } />
          <Route path='/formaciondebase' element={
            <ScrollToTop>
              <Formaciondebase />
            </ScrollToTop>

          } />
          <Route path='/dinamica' element={
            <ScrollToTop>
              <Dinamica />
            </ScrollToTop>
          } />
          <Route path='/autoestima' element={
            <ScrollToTop>
              <Autoestima />
            </ScrollToTop>
          } />
          <Route path='/metodosilva' element={
            <ScrollToTop>
              <Metodosilva />
            </ScrollToTop>
          } />
          <Route path='/metodologia' element={
            <ScrollToTop>
              <Metodologia />
            </ScrollToTop>
          } />
          <Route path='/instructora' element={
            <ScrollToTop>
              <Instructora />
            </ScrollToTop>
          } />
          <Route path='/testimonials' element={
            <ScrollToTop>
              <Testimonials />
            </ScrollToTop>

          } />
        </Routes>
      </Router >

    )
}

export default App

import React from "react";
import { Navbar } from "../../components";
import { banner } from "../blog/imports";
import Footer from "../footer/Footer";
import './instructora.css';

const Instructora = () => {
  return (
    <div>
      <Navbar />
      <div className="gridd">
        <div className="instructora__banner">
          <img src={banner} alt="" />
        </div>
        <div className="instructora-content">
          <div className="instructora__title">
            <p>Instructora oficial del Método Silva</p>
            <h1 className="gradient__text">Licenciada Martha Pugliesi</h1>
          </div>
          <div className="instructora__text">
            <p>Me recibí de Maestra Normal Nacional y fuí maestra y directora de escuela hasta mi retiro. Me gradué en la Universidad del Salvador de licenciada en Artes y Técnicas Publicitarias, profesión que no ejercí porque me apasionó la docencia, primero en la actividad escolar, y luego como instructora del Método Silva desde el año 1979. <br />
              Impartí en el año 1990 talleres de Técnicas de Estudio y Autoestima en la escuela número 9 (CABA) <br />
              Fuí conferencista en la Convención Internacional en Laredo-Texas (Año 1993).<br />
              Master en Psicorientología - Laredo-Texas.<br />
              Estudios complementarios que me formaron y seguirán formando: <br />
              Programación Neurolinguística (PNL).<br />
              Ho´oponopono<br />
              Reiki<br />
              Técnicas de Energía Cósmica<br />
              Sistema Isha<br />
              <br /><br />
              Técnicas de integración cerebral: <br />
              EMDR (Movimiento ocular, Desencibilización y reprocesamiento 1 y 2)<br />
              EFT (Técnicas de Liberación Emocional 1 y 2)<br />
              PET (Técnica del barrido del dolor)<br />
              TIC (Técnicas de los anteojos hemisféricos)<br />
              NAET (Auto tratamiento Emocional)<br />
              NAEN (Método para borrar efectos negativos)<br />
              TAT (Técnica de Acupresión de Tapas).</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Instructora;
import React from "react";
import { Navbar } from "../../components";
import { dinamica } from "../blog/imports";
import Footer from "../footer/Footer";
import './cursos.css';

function Dinamica() {
  return (
    <div className="main">
      <Navbar></Navbar>
      <div className="container-header">
        <h1 className="gradient__text">Workshop Dinamica del Metodo Silva Virtual</h1>
      </div>
      <div className="cont-img"> <img src={dinamica} className='course-img' alt="" /></div>
      <div className="container-body">
        <p>
          Próximamente...
          {/* De 19 a  23:30 hs de Argentina - VIRTUAL POR ZOOM- */}
          <br /> <br />
          Este programa está dirigido a toda persona que desee MEJORAR su CALIDAD de vida. Está orientado al DESARROLLO de una consciencia de PROSPERIDAD en todas las áreas de la vida.
          Su práctica te convertirá en un IMÁN que te permitirá atraer a tu vida lo que deseas, y aprenderás a usar la ley de atracción en forma más efectiva.</p>
        <a href="https://api.whatsapp.com/send?phone=541134523591"><button className='button-header'>Contactate con nosotros</button></a>

      </div>
    </div>
  );
}

export default Dinamica;
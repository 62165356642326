import React from "react";
import { Navbar } from "../../components";
import { mspresencial } from "../blog/imports";
import Footer from "../footer/Footer";
import './ms.css';

const Metodologia = () => {
  return (
    <div>
      <Navbar />
      <div className="msdata">
        <div className="msdata__container">
          <h1 className="gradient__text titlemore">Fundamentos Científicos del Método Silva</h1>
          <img src={mspresencial} alt="" />
          <p>Se sabe que el cerebro emite ondas eléctricas medibles con el electroencefalógrafo: beta, alfa, theta y delta. Existe una relación entre la producción de ondas cerebrales, los estados mentales y las actividades que desarrolla el ser humano. Comúnmente, si la persona está consciente (despierta) y actuando con sus sentidos físicos, predomina la producción de ondas Beta. Ahora bien, Alfa es una frecuencia mas lenta y mas energética que Beta y está relacionada con el subconsciente. Donde está su pensamiento, ahi está su experiencia. El hombre es lo que piensa. En el subconsciente se encuentran las capacidades creativas del hombre. El Método Silva enseña a emplear la frecuencia cerebral, de manera que se pueda permanecer consciente, produciendo mas Alfa a voluntad y utilizar sus posibilidades de forma práctica.</p>
        </div>
        <div className="msdata__container">
          <h1 className="gradient__text titlemore">¿En qué nos Beneficia el Método Silva?</h1>
          <p>Salud: El Método nos ayuda a corregir enfermedades psicosomáticas, eliminar y evitar dolores de cabeza tipo tensión y tipo migraña, sin drogas ni medicamentos, ayudando al médico en su corrección. Manejar problemas de tensión (stress) y/o emocionales. Mantener un buen estado de ánimo y dormir bien sin medicamentos.
            <br /><br />
            Mayor Concentración y Memoria: Mejorando notablemente en los estudios, en la capacidad de aprender y en la posterior utilización de lo que hemos aprendido.
            Aunque no seamos estudiantes, los resultados de nuestra proyección personal en nuestro trabajo y en su relación con los demás dependen en gran medida de nuestra capacidad de concentración y memoria, incremento de la imaginación y creatividad.
            <br /><br />
            Hábitos y Vicios: corregir el hábito de fumar (reducir o eliminarlo), comer y/o beber en exceso. Corregir hábitos de desorganización personal, pereza en el hogar o en el trabajo. Cambiar hábitos indeseables.
            <br /><br />
            Personalidad: Se logra aplomo, seguridad, confianza, integración, armonía con los demás, objetividad y capacidad para desarrollar una fuerte autoestima.
            Miedos, irascibilidad, impaciencia, son solo algunos de los muchos problemas que soluciona con la práctica del Método Silva.
            <br /><br />
            Relaciones Humanas: Familiares, sociales y laborales, se mejoran al aprender técnicas para buscar soluciones a los problemas y aplicarlas desarrollando, además, una actitud positiva hacia las personas que nos rodean y hacia la actividad que desarrollamos.</p>
        </div>
        <div className="msdata__container">
          <h1 className="gradient__text titlemore">¿Qué beneficios me da y de que me sirve el Desarrollo Mental si no tengo problemas?</h1>
          <p>Para actuar de forma preventiva y mantener buena salud, equilibro emocional, estabilidad en la relaciones con los demás, desarrollarnos, crecer y evolucionar como seres humanos y poder ayudar a nuestros seres queridos y a cualquier ser humano si así lo deseamos. Contribuyendo a mejorar la calidad de vida en nuestro entorno.</p>
        </div>
        <p className="gradient__text frase">La felicidad está adentro de nosotros mismos y es el estado natural de una mente bien dirigida</p>
      </div>
      <Footer />
    </div>
  );
}

export default Metodologia;
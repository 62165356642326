import React from 'react';
import { Link } from 'react-router-dom';
import msLogo from '../../assets/logo-1.png';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding" id='footer'>
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">¿Estás interesado en un curso del Método Silva?</h1>
    </div>

    <div className="gpt3__footer-btn">
      <a href="https://api.whatsapp.com/send?phone=541134523591">
        <p>Envianos un mensaje!</p>
      </a>
    </div>

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={msLogo} alt="MetodoSilva_logo" />
        <p>Silva Method, <br /> All Rights Reserved</p>
      </div>

      <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <Link to='/'>
          <p>Inicio</p>
        </Link>
        <Link to='/#metodosilva'>
          <p>¿Qué es?</p>
        </Link>
        <Link to='/#features'>
          <p>Información</p>
        </Link>
        <Link to='/#possibility'>
          <p>Instructora</p>
        </Link>
        <Link to='/metodosilva'>
          <p>Más sobre el Método Silva</p>
        </Link>
      </div>

      <div className="gpt3__footer-links_div">
        <h4>Cursos</h4>
        <Link to='/formaciondebase'>
          <p>Curso de Formación de Base</p>
        </Link>
        <Link to='/dinamica'>
          <p>Dinámica del Método Silva</p>
        </Link>
        <Link to='/autoestima'>
          <p>La fuerza transformadora de la Autoestima</p>
        </Link>
        <a href='https://api.whatsapp.com/send?phone=541134523591'>
          <p>Coaching</p>
        </a>
        <a href='https://api.whatsapp.com/send?phone=541134523591'>
          <p>Reuniones de graduados</p>
        </a>
      </div>

      <div className="gpt3__footer-links_div">
        <h4>Contacto</h4>
        <a href="https://api.whatsapp.com/send?phone=541134523591">
          <p>+54 1134523591</p>
        </a>
        <a href="https://www.instagram.com/marthapugliesi/">
          <p>https://www.instagram.com/marthapugliesi/</p>
        </a>
        <a href="https://www.facebook.com/martha.pugliesi/">
          <p>https://www.facebook.com/martha.pugliesi/</p>
        </a>
        <a href="mailto:elmetodosilva.buenosaires@gmail.com">
          <p>elmetodosilva.buenosaires@gmail.com</p>
        </a>
      </div>

    </div>

    <div className="gpt3__footer-copyright">
      <p>@2022 Lautaro Lazzeri.</p>
    </div>
</div>
);

export default Footer;